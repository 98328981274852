export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      'pickup_place_cc': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Comanda inregistrata',
          text: 'Comanda dumneavoastra a fost inregistrata si urmeaza sa fie procesata in cel mai scurt timp',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Plata cu cardul nu a fost finalizata',
          text: 'Va rugam sa verificati inbox-ul e-mail-ului dumneavoastra (incluzand folderul SPAM), pentru a putea plati comanda/rezervarea pe care ati plasat-o pe site-ul nostru, urmand pasii din e-mail.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Plata cu cardul a fost finalizata cu succes',
          text: 'Plata cu cardul initiata de dumneavoastra pentru comanda plasata a fost finalizata cu succes',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Comanda pregatita pentru livrare',
          text: 'Comanda dumneavoastra se afla in curs de pregatire',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Intampinam probleme la comanda dumneavoastra',
          text: 'Va rugam sa contactati centrul de asistenta pentru a afla mai multe inforrmatii cu privire la comanda/rezervarea dumneavoastra.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_packed',
          label: 'Comanda a fost impachetata',
          text: '',
          variant: 'success',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Comanda in curs de livrare',
          text: 'Comanda dumneavoastra a fost predata catre curier si este in curs de livrare',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Comanda gata de ridicare',
          text: 'Comanda dumneavoastra se afla in farmacia selectata si poate fi ridicata',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Comanda finalizata',
          text: 'Comanda a fost finalizata cu succes',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Comanda anulata',
          text: 'Daca doriti sa aflati mai multe informatii despre comanda dumneavoastra, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'Timpul de ridicare a expirat',
          text: 'Comanda dumneavoastra nu a fost ridicata in timpul alocat ridicarii acesteia. Pentru mai multe detalii, va rugam sa contactati telefonic, centrul de asistenta.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'pickup_place_box': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Comanda inregistrata',
          text: 'Comanda dumneavoastra a fost inregistrata si urmeaza sa fie procesata in cel mai scurt timp',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Plata cu cardul nu a fost finalizata',
          text: 'Va rugam sa verificati inbox-ul e-mail-ului dumneavoastra (incluzand folderul SPAM), pentru a putea plati comanda/rezervarea pe care ati plasat-o pe site-ul nostru, urmand pasii din e-mail.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Plata cu cardul a fost finalizata cu succes',
          text: 'Plata cu cardul initiata de dumneavoastra pentru comanda plasata a fost finalizata cu succes',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Comanda pregatita pentru livrare',
          text: 'Comanda dumneavoastra se afla in curs de pregatire',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Intampinam probleme la comanda dumneavoastra',
          text: 'Va rugam sa contactati centrul de asistenta pentru a afla mai multe inforrmatii cu privire la comanda/rezervarea dumneavoastra.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Comanda in curs de livrare',
          text: 'Comanda dumneavoastra a fost predata catre curier si este in curs de livrare',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Comanda gata de ridicare',
          text: 'Comanda dumneavoastra se afla in farmacia selectata si poate fi ridicata',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Comanda finalizata',
          text: 'Comanda a fost finalizata cu succes',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Comanda anulata',
          text: 'Daca doriti sa aflati mai multe informatii despre comanda dumneavoastra, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Returul a ajuns in depozit',
          text: 'Comanda dumneavoastra nu a fost livrata la domiciliu /ridicata din farmacie deoarece este transportata catre depozit. Daca aveti intrebari cu privire la aceasta, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'delivery': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Comanda inregistrata',
          text: 'Comanda dumneavoastra a fost inregistrata si urmeaza sa fie procesata in cel mai scurt timp',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Plata cu cardul nu a fost finalizata',
          text: 'Va rugam sa verificati inbox-ul e-mail-ului dumneavoastra (incluzand folderul SPAM), pentru a putea plati comanda/rezervarea pe care ati plasat-o pe site-ul nostru, urmand pasii din e-mail.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Plata cu cardul a fost finalizata cu succes',
          text: 'Plata cu cardul initiata de dumneavoastra pentru comanda plasata a fost finalizata cu succes',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Comanda pregatita pentru livrare',
          text: 'Comanda dumneavoastra se afla in curs de pregatire',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Intampinam probleme la comanda dumneavoastra',
          text: 'Va rugam sa contactati centrul de asistenta pentru a afla mai multe inforrmatii cu privire la comanda/rezervarea dumneavoastra.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_packed',
          label: 'Comanda a fost impachetata',
          text: '',
          variant: 'success',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Comanda in curs de livrare',
          text: 'Comanda dumneavoastra a fost predata catre curier si este in curs de livrare',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Comanda finalizata',
          text: 'Comanda a fost finalizata cu succes',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Comanda anulata',
          text: 'Daca doriti sa aflati mai multe informatii despre comanda dumneavoastra, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Returul a ajuns in depozit',
          text: 'Comanda dumneavoastra nu a fost livrata la domiciliu /ridicata din farmacie deoarece este transportata catre depozit. Daca aveti intrebari cu privire la aceasta, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'delivery_big-format': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Comanda inregistrata',
          text: 'Comanda dumneavoastra a fost inregistrata si urmeaza sa fie procesata in cel mai scurt timp',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Plata cu cardul nu a fost finalizata',
          text: 'Va rugam sa verificati inbox-ul e-mail-ului dumneavoastra (incluzand folderul SPAM), pentru a putea plati comanda/rezervarea pe care ati plasat-o pe site-ul nostru, urmand pasii din e-mail.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Comanda in curs de procesare',
          text: 'Comanda dumneavoastra se afla in curs de pregatire',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Intampinam probleme la comanda dumneavoastra',
          text: 'Va rugam sa contactati centrul de asistenta pentru a afla mai multe inforrmatii cu privire la comanda/rezervarea dumneavoastra.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: '', // such status won't be used
          text: '', // such status won't be used
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: '', // such status won't be used
          text: '', // such status won't be used
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Comanda in curs de livrare',
          text: 'Comanda dumneavoastra a fost predata catre curier si este in curs de livrare',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Comanda gata de ridicare',
          text: 'Comanda dumneavoastra se afla in farmacia selectata si poate fi ridicata',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Comanda finalizata',
          text: 'Comanda a fost finalizata cu succes',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Comanda anulata',
          text: 'Daca doriti sa aflati mai multe informatii despre comanda dumneavoastra, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: '',
        },
      ],
      'reservation': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Comanda inregistrata',
          text: 'Comanda dumneavoastra a fost inregistrata si urmeaza sa fie procesata in cel mai scurt timp',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Plata cu cardul nu a fost finalizata',
          text: 'Va rugam sa verificati inbox-ul e-mail-ului dumneavoastra (incluzand folderul SPAM), pentru a putea plati comanda/rezervarea pe care ati plasat-o pe site-ul nostru, urmand pasii din e-mail.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Plata cu cardul a fost finalizata cu succes',
          text: 'Plata cu cardul initiata de dumneavoastra pentru comanda plasata a fost finalizata cu succes',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Comanda in curs de procesare',
          text: 'Comanda dumneavoastra se afla in curs de pregatire',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Comanda gata de ridicare',
          text: 'Comanda dumneavoastra se afla in farmacia selectata si poate fi ridicata',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Comanda finalizata',
          text: 'Comanda a fost finalizata cu succes',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Comanda anulata',
          text: 'Daca doriti sa aflati mai multe informatii despre comanda dumneavoastra, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'Timpul de ridicare a expirat',
          text: 'Comanda dumneavoastra nu a fost ridicata in timpul alocat ridicarii acesteia. Pentru mai multe detalii, va rugam sa contactati telefonic, centrul de asistenta.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'pharmacy_purchase': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Comanda inregistrata',
          text: 'Comanda dumneavoastra a fost inregistrata si urmeaza sa fie procesata in cel mai scurt timp',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Comanda finalizata',
          text: 'Comanda a fost finalizata cu succes',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Comanda anulata',
          text: 'Daca doriti sa aflati mai multe informatii despre comanda dumneavoastra, va rugam sa contactati telefonic centrul de asistenta.',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
