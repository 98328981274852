import { envBool } from '@nsf/utils/EnvUtils.js'

export const modules = [
  '@nsf/datadog',
  ...(process.env.NUXT_PUBLIC_DEBUGGING_MODE_ENABLED ? ['@nsf/debugging'] : []),
  '@nsf/metrics',
  '@nsf/i18n',
  ...(!process.env.STORYBOOK_BUILD ? ['@nsf/csp'] : []),
  '@nsf/ui-library',
  '@nsf/base',
  '@nsf/elasticsearch',
  '@nsf/algolia',
  '@nsf/search',
  '@nsf/articles',
  '@nsf/article-preview',
  '@nsf/pickup-select',
  '@nsf/pickup-select-v2',
  '@nsf/apotheka-web',
  '@nsf/apotheka-widget',
  '@nsf/delivery-fe',
  '@nsf/brands',
  '@nsf/sukl-widget',
  '@nsf/checkout',
  '@nsf/homepage',
  '@nsf/catalog',
  '@nsf/faq',
  '@nsf/gtm',
  '@nsf/speed-curve',
  '@nsf/subscription',
  '@nsf/loyalty-page',
  '@nsf/my-account',
  '@nsf/cms',
  '@nsf/product-preview',
  '@nsf/delivery-fe',
  '@nsf/adults-only',
  '@nsf/tags',
  '@nsf/3for2',
  '@nsf/clubs',
  '@nsf/product-relationship-loader-persoo',
  '@nsf/product-relationship-loader-placement',
  '@nsf/address-validation',
  '@nsf/validations',
  '@nsf/local-ro-drmax',
  '@nsf/features',
  '@nsf/web-chat-widget',
  '@nsf/convert',

  // health-check module should be the last one due to its startup probe which should check the whole app
  '@nsf/health-check',
]

// We need my-account-sorp with bootstrap but we need overriding routes only in case MY_ACCOUNT_SORP_ENABLED is true, thats why this
if (envBool(process.env.MY_ACCOUNT_SORP_ENABLED, false)) {
  modules.push('@nsf/my-account')
  modules.push('@nsf/my-account-sorp')
} else {
  modules.push('@nsf/my-account-sorp')
  modules.push('@nsf/my-account')
}

export default { modules }
